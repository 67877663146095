.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.title {
		font-weight: 700;
	}

}