.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;

		&:before {
			top: 6px;
		}

		&:after {
			top: 5px;
		}
	}

	.red {
		color: var(--color-red);
	}
}

.unit-contact-form-v1 {
	.checkbox {
		label {
			&:before {
				top: 6px;
			}

			&:after {
				top: 5px;
			}
		}
	}
}