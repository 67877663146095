.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

	.cookie-dlg {
		color: var(--font-color-main);
	}

}

.footer-logo {
	background: #e7f5ff;


	.container-xl {
		@media (max-width:767px) {
			padding-top: 2rem !important;
			padding-bottom: 2rem !important;

		}

		ul {
			display: flex;
			justify-content: space-between;
			margin: 0;

			li {
				margin: 0 10px;

				img {
					max-height: 150px;

					@media (max-width:991px) {
						max-width: 100%;

					}
				}
			}
		}
	}
}

#back-to-up {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	font-size: 18px;
	border: none;
	outline: none;
	background-color: #a3c50f;
	color: #686866;
	cursor: pointer;
	padding: 15px 20px;
	border-radius: 50%;
	box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, .2);
	color: #fff;

	@media (max-width:1100px) {
		padding: 7px 12px;
		bottom: 20px;
		right: 5px;
		width: 40px;
		height: 40px;

	}


}