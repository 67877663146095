.static-view {
	margin-top: var(--page-margin-top);

	&.derhof {
		#luftaufnahmen {
			img {
				width: 100%;
			}
		}
	}

	.video-bl {
		video {
			width: 100%;
		}
	}
}